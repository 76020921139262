import React, {Component, useState, useEffect} from "react";
import apiClient from "../helpers/api";
import { useParams } from "react-router";
import DOMPurify from 'dompurify';

import PageShortcodes from "../services/PageShortcodes";
import { getShortCodeName, setShortCodeParam } from "../helpers/shortcode/Shortcode";
import parse from 'html-react-parser';
import regexifyString from "regexify-string";
import {Helmet} from "react-helmet";


//Page components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Banner from "../components/Banner/Banner";
import ModalLogin from "../components/Modal/ModalLogin";
import PageLoading from "../components/FullPageLoading/PageLoading";
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import PageNotFound from '../components/PageNotFound/PageNotFoundPage'
import Register from "../components/Register/Register";
import ModalRegister from "../components/Modal/ModalRegister";


// const SinglePage = () => {
//     let params = useParams()
    

//     const [data, setData] = useState(null);
//     const [isLoading, setIsLoading] = useState(true)
//     const [isError, setIsError] = useState(false)

//     const getPageContent = async () => {

//         await axios.get(`${process.env.REACT_APP_API}/page/${params.url}`)
//         .then(res => {
//             console.log(res);
//             if(res.data.status === 404){
//                 setIsError(true)
//             }
//             setData(res.data.data)
//         }).catch(e => {
//             console.log(e);
//         })
        
//     }


//     useEffect(() => {
//         getPageContent()
//     }, [])

//     if (isError) {
//         return <PageNotFound/>
//     }

//     if (data) {
//         console.log(data)
//         let regex = data.content.content.match(/\[.*?\]/g);

//         console.log(regex);


//         if (regex.length > 0) {
//             regex.map( (val, key) => {
//                 console.log(val);
//                 let shortcodename = getShortCodeName(val)
//                 let shortcode
                
//                 if (Array.isArray(data[shortcodename])) {
//                    shortcode = setShortCodeParam(shortcodename, data[shortcodename][0])
//                 }else{
//                    shortcode = setShortCodeParam(shortcodename, this.state.data[shortcodename])
//                 }

//                 console.log(shortcode);
//             })
//         }

        
//     }
        

//     const html = "<div>asdfasdf[register]</div>";

//     const result = regexifyString({
//         pattern: /\[.*?\]/gim,
//         decorator: (match, index) => {
//             let short = getShortCodeName(match)

//             let shortcode
                
//             if (Array.isArray(data[short])) {
//                 shortcode = setShortCodeParam(short, data[short][0])
//             }else{
//                 shortcode = setShortCodeParam(short, this.state.data[short])
//             }

//             console.log(shortcode);
            

//             return PageShortcodes[short]([]);
//         },
//         input: data && data.content.content,
//     });

//     console.log(result);

//     return (
            
//         <div>
//             <Header/>
//             <Banner/>
//             {result}
//             <ModalLogin/>
//             <Footer/>
//             <ModalSearch />
//             <ModalMenu />
//             <Scrollup />
              
//         </div>
//     );


// }


class SinglePage extends Component {
    
    state = { 
        data: [],
        content: [],
        page_content: [],
        content_data: [],
        isShortcode: false,
        isError: false
     } 

    componentDidMount(){
        
        apiClient.get(`${process.env.REACT_APP_API}/page/${this.props.match.params.url}`)
        .then(res => {
            //console.log(res)

            if (res.data.status == 404) {
                this.setState({
                    isError: true
                })
            }
            this.setState({
                data: res.data.data,
                content: res.data.data.content,
                content_data: res.data.data.content
            })
            
            let regex = this.state.content.content.match(/\[.*?\]/g);

            if (regex.length > 0) {
                this.setState({
                    isShortcode: true
                })
                regex.map( (val, key) => {
                  
                    let shortcodename = getShortCodeName(val)
                    let shortcode
                    
                    if (Array.isArray(this.state.data[shortcodename])) {
                       shortcode = setShortCodeParam(shortcodename, this.state.data[shortcodename][0])
                       console.log(shortcode);
                      
                    }else{
                       shortcode = setShortCodeParam(shortcodename, this.state.data[shortcodename])
                    }

                 

                    this.setState(prevState => ({
                        page_content: [...prevState.page_content, {
                            id: key,
                            shortcode: shortcode.shortcode,
                            param: shortcode.param
                        }]
                    }))

                })


            }

        }).catch( e => {
            console.log(e);
        })

    } 


    render() { 
        return (
           
            <div>
                { !this.state.isError  ? (
                    <>
                    <Helmet>
                    <title>{this.state.content_data && this.state.content_data.seo_title }</title>
                    <meta name="robots" content="all" data-react-helmet="true" />
                    <meta name="keywords" content={this.state.content_data && this.state.content_data.keywords}></meta>
                    <meta name="title" content="{this.state.content_data && this.state.content_data.seo_title }"></meta>
                    <meta name="description" content="Enable Artists to connect humanity across time. Increase the Value of the Artist's works as it becomes recognizable in more Hearts and Homes."></meta>
                    <meta property="og:type" content="website"></meta>
                    <meta property="og:url" content="http://staging.scarletbox.io/"></meta>
                    <meta property="og:title" content="ScarletBox.io"></meta>
                    <meta property="og:description" content="Enable Artists to connect humanity across time. Increase the Value of the Artist's works as it becomes recognizable in more Hearts and Homes."></meta>
                    <meta property="og:image" content="https://scarletbox.io/img/scarlet_box.jpg"></meta>

                    <meta property="twitter:card" content="summary_large_image"></meta>
                    <meta property="twitter:url" content="http://staging.scarletbox.io/"></meta>
                    <meta property="twitter:title" content="ScarletBox.io"></meta>
                    <meta property="twitter:description" content=""></meta>
                    <meta property="twitter:image" content="https://scarletbox.io/img/scarlet_box.jpg"></meta>
                    {this.state.content_data.meta}
                   
                    </Helmet>
                    <Header/>
                    <Banner title={this.state.content.title}/>
                    {
                        this.state.isShortcode ? (
                        this.state.page_content.map((comp, key) => {
                            let shortcode = new PageShortcodes(comp.param)
                            return PageShortcodes[comp.shortcode](comp.param)
                            
                        })) : (
                            <div className="container py-5 px-3 text-justify text-black" dangerouslySetInnerHTML={{__html: this.state.content.content}}></div>
                        )
                    }
                    <ModalLogin/>
                    <ModalRegister/>
                    <Footer/>
                    <ModalSearch />
                    <ModalMenu />
                    <Scrollup />
 
                    </>
                ) : (
                    <>
                    <PageNotFound/>
                    </>
                )
            }
                
            </div>
        );
    }
}
 
export default SinglePage;