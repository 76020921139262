
import React, {Component, useEffect, useState} from "react";


class ModalArtist extends Component{


    state = {
        content: ''

    };

    componentDidMount(){
        this.setState({
            content: this.props
         
          
        })
    //  console.log(this.props.content)

    };


    render() {

        // console.log(this.props.content.id)

            return (

                <div id={`modal-artist-${this.props.content.artist_id}`} className="modal fade p-0">
                    <div className="modal-dialog modal-xl modal-dialog-centered">
                        <div className="modal-content m-2">
                           
                            <div className="modal-header" data-dismiss="modal" style={{borderBottom:'1px solid #e3e3e3'}}>
                            <span style={{fontSize:'22px'}}>Artist</span> <i className="far fa-times-circle icon-close" />
                            </div>
                            <div className="modal-body pt-0">
                           
                                {/* <div className="card-body text-center pt-0">
                                    <img className="avatar-lg" src={window.photo_url+this.props.content.artist.photo} alt="" />
                                    <h4 className="text-red">{this.props.content.artist.artist_name}</h4>
                                    <div className="text-justify" dangerouslySetInnerHTML={{__html: this.props.content.artist.description}}></div>

                                </div> */}

                                <div className="row p-3 d-flex  justify-content-center">
                                    <div className="col-12 col-lg-5 py-1">
                                        <img
                                        className="img-fluid"
                                        src={window.photo_url+this.props.content.artist.photo}
                                        alt=""
                                       
                                    />
                                    </div>
                                    <div className="col-12 col-lg-7 py-1 ">
                                        <h2 className="text-dark normal-font my-1">{this.props.content.artist.artist_name}</h2>
                                        <div className="text-justify font-weight-normal" dangerouslySetInnerHTML={{__html: this.props.content.artist.description}}></div>

                                    </div>
                                </div>  

                                
                            </div>
                        </div>
                    </div>
                </div>
                      
            );
            
        


     
    }
       
}
 
export default ModalArtist;

