import React, {useState, useEffect} from 'react';

import Faq from '../../components/Vip/Faq';
import Artists from '../../components/Vip/Artists';
import Arts from '../../components/Vip/Arts';
import TabSkeleton from '../../Skeleton/Vip/TabSkeleton';
import apiClient from "../../helpers/api";
import { isMobile } from "react-device-detect";
const Tabs = (props) => {
    const [shipping, setShipping] = useState('')
    const [productDetails, setProductDetails] = useState('')
    const [disclaimer, setDisclaimer] = useState('')
    const [isloaded, setIsLoaded] = useState(false)
    const noBorder = {
        borderRadius:"0px"
    }

    useEffect(() => {

        
        
        apiClient.get(`${process.env.REACT_APP_API}/vip/get/shiping_product_details/${props.vipid}`)
            .then(async res => {
                
                setShipping(res.data.result.shipping_details);
                setProductDetails(res.data.result.product_details);
                setDisclaimer(res.data.result.disclaimer);
                
              setIsLoaded(true)
        }).catch( err => {
            console.log(err)
        })

        
    }, []);

    return (
        <div className="container py-2 pb-5 px-3 text-justify text-black">
        {
            isloaded ? 
           
        <div className="row items d-flex justify-content-center pb-5">
            <div className="col-md-12" >
            <ul class="nav  nav-pills nav-justified overflow-x-scroll " id="myTab" role="tablist" style={{whiteSpace:isMobile ? 'nowrap' : '',overflowX:isMobile ? 'auto' : 'unset',flexWrap:isMobile ? 'nowrap' : 'wrap'}}>
                <li className="nav-item ">
                    <a className="bktnav-link nav-link active" style={noBorder} id="collection-tab" data-toggle="tab" href="#collection" role="tab" aria-controls="collection" aria-selected="true" ref={(node) => 
        node?.style.setProperty("color", "#fff", "important")
    }>Collections</a>
                </li>
                <li className="nav-item ">
                    <a className="bktnav-link nav-link" id="artist-tab" style={noBorder} data-toggle="tab" href="#artist" role="tab" aria-controls="artist" aria-selected="false" ref={(node) => 
        node?.style.setProperty("color", "#fff", "important")
    }>About The Artist</a>
                </li>
                <li className="nav-item ">
                    <a className="bktnav-link nav-link" id="product_details-tab" style={noBorder} data-toggle="tab" href="#product_details" role="tab" aria-controls="product_details" aria-selected="false" ref={(node) => 
        node?.style.setProperty("color", "#fff", "important")
    }>Product Details</a>
                </li>
                <li className="nav-item ">
                    <a className="bktnav-link nav-link" id="shipping-tab" style={noBorder} data-toggle="tab" href="#shipping" role="tab" aria-controls="shipping" aria-selected="false" ref={(node) => 
        node?.style.setProperty("color", "#fff", "important")
    }>Shipping & Delivery</a>
                </li>
                <li className="nav-item ">
                    <a className="bktnav-link nav-link" id="faq-tab" style={noBorder} data-toggle="tab" href="#faq" role="tab" aria-controls="faq" aria-selected="false" ref={(node) => 
        node?.style.setProperty("color", "#fff", "important")
    }>FAQ</a>
                </li>
            </ul>
                <div className="tab-content" id="myTabContent">
                <Arts vipdata={props.vipdata} disclaimer={disclaimer} stateChanger={props.stateChanger} vipidc={props.vipid} partner={props.partner} />
                <Artists vipida={props.vipid} />
                <div className="tab-pane fade" id="product_details" role="tabpanel" aria-labelledby="product_details-tab">
                    <h3 className='text-dark'>Product Details</h3>
                    <p  dangerouslySetInnerHTML={{__html: productDetails}}></p>
                </div>
                <div className="tab-pane fade" id="shipping" role="tabpanel" aria-labelledby="shipping-tab">
                    <h3 className='text-dark'>Delivery Details</h3>
                    <p  dangerouslySetInnerHTML={{__html: shipping}}></p>
                </div>
                <Faq vipid={props.vipid} />
            </div>
            </div>
        </div>
      
            : <><TabSkeleton/></>
        }
          </div>
    );

};
export default Tabs;
