import React, {Component, useState, useEffect} from "react";


// const PageLoading = () => {

//     const [isLoading, setIsLoading] = useState(true);

//     useEffect(() => {



//     })

//     return (
//         <div id="netstorm-preloader" className="netstorm-preloader">
//                 <div className="preloader-animation">
//                     <div className="spinner"></div>
//                     <p className="fw-5 text-center text-uppercase">Loading</p>
//                 </div>
//                 <div className="loader-animation">
//                     <div className="row h-100">
//                         <div className="col-3 single-loader p-0">
//                             <div className="loader-bg"></div>
//                         </div>
//                         <div className="col-3 single-loader p-0">
//                             <div className="loader-bg"></div>
//                         </div>
//                         <div className="col-3 single-loader p-0">
//                             <div className="loader-bg"></div>
//                         </div>
//                         <div className="col-3 single-loader p-0">
//                             <div className="loader-bg"></div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//     );


// }


class PageLoading extends Component {
    state = {
        isLoading: true,
        title:'Loading',
        longMsg:false
    }

    componentDidMount(){
        
        if(typeof this.props.title !== 'undefined'){
            this.setState({
                title: this.props.title,
                longMsg: true
            })
            if(this.props.secondload > 0){
                this.setState({
                    title: this.props.title,
                    longMsg: true
                }) 
            }
        } else {
            setTimeout( () => {
                this.setState({
                    isLoading: false
                })
            }, 2000)
        } 

        
    }

    render() { 
        return (
            <div id="netstorm-preloader" className="netstorm-preloader">
                    <div className="preloader-animation">
                        <div className="spinner"></div>
                        <p className="fw-5 text-center text-uppercase" dangerouslySetInnerHTML={{ __html: this.state.title }}></p>
                        {this.state.longMsg ?
                        <>
                        <p className="fw-5 text-center text-uppercase"></p>
                        <p className=" text-center ">
                            After 5 minutes, your NFT will be dropped if payment goes through.  <br></br><br></br>  
                            To check, go to your My Account and click My Collections<br></br> 
                           
                            You should be able to see your nft as a spinning red box.<br></br>
                            Email us for questions: <a href="mailto:marketing@scarletbox.io" target="_blank">marketing@scarletbox.io</a>
                        </p>
                        <p className="fw-5 text-center text-uppercase"></p>
                        <p className="fw-5 text-center text-uppercase"> </p>

                         
                        </> : ''
                        }
                    </div>
                    <div className="loader-animation">
                        <div className="row h-100">
                            <div className="col-3 single-loader p-0">
                                <div className="loader-bg"></div>
                            </div>
                            <div className="col-3 single-loader p-0">
                                <div className="loader-bg"></div>
                            </div>
                            <div className="col-3 single-loader p-0">
                                <div className="loader-bg"></div>
                            </div>
                            <div className="col-3 single-loader p-0">
                                <div className="loader-bg"></div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}
 
export default PageLoading;