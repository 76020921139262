const AuctionItem = (props) => {

    const btn_outline_red = {
        border: '1px solid #c31b01',
        backgroundColor: '#fffff',
        color: '#c31b01'
    }

    return (
        <div className="row border my-5 p-5">
            <div className="col-md-3">
                <img src={props.art_details && props.art_details._mint_file}/>
            </div>
            <div className="col-md-9">
                <p className="my-2 font-weight-bolder" style={{fontSize: '20px'}}>{props.label}</p>
                <p className="my-2 font-weight-bold">Artist: {props.artist && props.artist.artist_name}</p>
                <p className="my-2 " dangerouslySetInnerHTML={{ __html: props.art_details.description}}>
                </p>
 
                {
                    props.free ? <p className="text-danger font-weight-bold text-uppercase my-2 mb-1" style={{cursor: "pointer"}} onClick={props.viewMerch}><u>{props.merch_title ? props.merch_title : "View Merch"}</u></p> : ""

                }

                {
                    props.highest_offer ? <p className="my-0"><span className="font-weight-bold">Highest Bid:</span> PHP {props.highest_offer && props.highest_offer.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits: 2})}</p> : ""
                }
              
                {
                    props.base_price  ? 
                    <p className="my-0"><span className="font-weight-bold">Starting Price:</span> PHP {props.base_price && props.base_price.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits: 2})}</p> : ""
                } 

                {

                props.auctionallowed == 0 ?  <p className="my-2"><span className=" text-danger text-uppercase font-weight-bold">Coming Soon</span></p> : 
                props.auctionstatus == 1 ? <button type="button" className="btn btn-danger rounded-0 px-5 mb-3" data-auction-id={props.auction_id} data-auction-art-id={props.auction_art_id} onClick={props.placeBids}> Place BID </button> : <><span className=" text-danger text-uppercase font-weight-bold">Auction Ended</span><br></br></>

                }

            </div>
        </div>
    );
}
 
export default AuctionItem;