import React, {Component} from "react";
import ModalArtist from "../Modal/ModalArtist";

class Artist extends Component {
    render() { 
        return (
            <>


            <div className="row items d-flex justify-content-center pb-5" > 
                {/* <div className="col-12 "> */}
                <div className={window.location.pathname == "/artists" ? "d-none" : "col-12 "}>

                    <h2 className="mb-4 text-center normal-font text-dark">
                       { this.props.params.length == 1 ? "Artist" : "Artists"}
                    </h2>
                </div>  
                <div className="container-fluid px-4" >
                    <div className="row justify-content-center"  >   
                    
                        {  
                             
                            this.props.params.map((res, id)=> {
                                return (
                                    <>
                                    <span id="the-artists"></span> 
                                    <div key={id} id={res.artist.id} className="col-lg-3 col-md-6  item d-flex flex-column align-items-center justify-content-between" >
                                    
                                            
                                        <div className="image-over mb-3">
                                            <img className="card-img-top h-100 img-fluid" src={window.photo_url+res.artist.photo} alt="" loading="lazy"/>
                                        </div>
                                        <div>
                                        {res.artist.sub_heading ? <p className="mb-0 text-dark text-center normal-font text-uppercase">{res.artist.sub_heading}</p> : ""}
                                        <h3 className="artist_name text-dark text-center normal-font text-uppercase mt-2">{res.artist.artist_name}</h3>
                                        </div>
                                    
                                        {/* <div className="col-12 text-left text-truncate pb-3 text-white" style={{height: "40px"}} dangerouslySetInnerHTML={{__html: res.artist.description}}></div> */}
                                        <button type="button" className="btn btn-smaller btn-dark font-weight-normal" data-toggle="modal" data-target={`#modal-artist-${res.artist_id}`}>Learn More</button>
                                        
                                        
                                    
                                    </div>
                                    <ModalArtist content={ res } />

                                    </>
                                
                                    
                                );
                            
                            })
                                
                        }
                       
                    </div>
                  
                </div>
                
          
            </div>
           
            </>
            
        );
    }
}
 
export default Artist;