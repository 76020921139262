import { useState, useEffect, useContext, useLayoutEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm, } from "react-hook-form";
import { Alert } from "react-bootstrap";

import { HeaderContext } from "../../helpers/headerContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import apiClient from "../../helpers/api";
import Auth from "../../helpers/auth/Auth";

const eye = <FontAwesomeIcon icon={faEye} />; 
const eyelash = <FontAwesomeIcon icon="fa-solid fa-eye-slash" />;

const ModalAuctions = (props) => {

    const isShow = props.isShow
    const closeModal = props.closeModal
    const source = props.auction_code
    const auction_art_id = props.auction_art_id

    const { changeClient, setChangeClient } = useContext(HeaderContext);

    const [isLogin, setIsLogin] = useState(true);
    const [art_details, setArtDetails] = useState({});
    const [bids, setBids] = useState([]);
    const [highestBid, setHighestBid] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState({show: false, msg: ""});
    const [user, setUser]   = useState({});
    const [modalType, setModalType] = useState('login');

    const {register, handleSubmit, setError, formState:{errors}, formState, reset} = useForm({ mode: 'onBlur' })
    const {isSubmitting} = formState;

    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [confirmpasswordType, setConfirmPasswordType] = useState("password");

    //toggle view icons
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const togglePassword = () => {
        if(passwordType==="password"){
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const toggleConfirmPassword =()=>{
        if(confirmpasswordType==="password"){
            setConfirmPasswordType("text")
            return;
        }
        setConfirmPasswordType("password")
    }

    // toggle login view
    const onClickRegister = () => {
        setIsLogin(false)
    }
    const onClickLogin = () => {
        setIsLogin(true)
    }

    //get art detail
    const getArtDetail = async () => {
        try {
            setIsLoading(true)
            await verifyLogin()
            await apiClient.get(`${process.env.REACT_APP_API}/auctions/get/auction_art_details/${auction_art_id}`).then(res => {
                
                setArtDetails(res.data.art)
                setBids(res.data.bids)
                setHighestBid(parseInt(res.data.highest_bid))
                setTimeout(() => {
                    setIsLoading(false)
                    props.setModalType('auction_bids')
                },300)
            }).catch( err => {
                setIsLoading(false)
            })
        } catch (error) {
            setIsLoading(false)
        }
    }

    const verifyLogin = async () => {
        try {
            const res = await apiClient.get(`${process.env.REACT_APP_API}/account/user`);
            if (Auth.isAuthenticated() && res.status == 200) {
                setUser(res.data)
                setModalType('auction_bids')
                return true;
            } 
        } catch (error) {
            setModalType("login")
            return false;
        }
    }

    //handle submit login and register 
    const onSubmitAuth = (data) => {
        if (isLogin) {
            return new Promise(resolve => {
                apiClient.post(`${process.env.REACT_APP_API}/auth/login`, data).then(res => {
                    setIsLoading(true)
                    if (res.status == 200) {
                        Auth.storeAuth()
                        setChangeClient(true)
                        verifyLogin()
                        setTimeout(() => {
                            setIsLoading(false)
                            props.setModalType('auction_bids')
                        },1500)
                    }
                    resolve();
                }).catch(error => {
                    setShowAlert(prevState => ({ ...prevState, show: true,msg: error.response.data.error}))
                    resolve();
                })
            })
        }else{
            return new Promise(resolve => {
                apiClient.post(`${process.env.REACT_APP_API}/auth/register`, data).then(res => {
                    setIsLoading(true)
                    if (res.status == 200) {
                        Auth.storeAuth()
                        setChangeClient(true)
                        verifyLogin()
                        setTimeout(() => {
                            setIsLoading(false)
                            props.setModalType('auction_bids')
                        },1500)
                        
                    }
                    resolve();
                }).catch(error => {
                    if (error.response.data) {
                        const errors = error.response.data.errors
                        if (errors.name) {
                            setError("name", { type: "focus", message: errors.name[0]}, { shouldFocus: true })
                        }
                        if (errors.last_name) {
                            setError("last_name", { type: "focus", message: errors.last_name[0]}, { shouldFocus: true })
                        }
                        if (errors.email) {
                            setError("email", { type: "focus", message: errors.email[0]}, { shouldFocus: true })
                        }
                        if (errors.password) {
                            setError("password", { type: "focus", message: errors.password[0]}, { shouldFocus: true })
                        }
                        if (errors.confirm_password) {
                            setError("confirm_password", { type: "focus", message: errors.confirm_password[0]}, { shouldFocus: true })
                        }
                    }
                    resolve();
                })
            })
        }
        
    }

    //handle submit bid offer
    const onSubmitBid = (data) => {
        try {
            apiClient.post(`${process.env.REACT_APP_API}/auctions/process/offer/`, data).then(res => {
                if (res) {
                    setShowAlert(prevState => ({ ...prevState,
                        show: true,
                        msg: res.data.msg
                    }))
                    
                    getArtDetail()
                    props.getAuctionArts()
                    reset({
                        bid_amount: "",
                    })

                    setTimeout(()=>{
                        setShowAlert(prevState => ({ ...prevState,
                            show: false,
                            msg: ""
                        }))
                    }, 5000)
                }
            }).catch(error => {
                if (error.response.data) {
                    const errors = error.response.data.errors
                    if (errors.bidder_name) {
                        setError("bidder_name", { type: "focus", message: errors.bidder_name[0]}, { shouldFocus: true })
                    }
                    if (errors.bid_amount) {
                        setError("bid_amount", { type: "focus", message: errors.bid_amount[0]}, { shouldFocus: true })
                    }
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getArtDetail()
        
    }, [])

    if (modalType === "login") {
        return (
            <Modal size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered 
            show={isShow}>
                <Modal.Header className="pb-0">
                    <p className="font-weight-bold pt-3">{isLogin ? 'Login' : 'Register'}</p> <button type="button" className="btn btn-dark btn-sm rounded-0 p-2" onClick={closeModal}>x</button> 
                </Modal.Header>
                {!isLoading ? (
                    <Modal.Body className="pt-0">
                        <div className="alert alert-dark rounded-0" role="alert">
                            <p className="small">To BID, please REGISTER, or LOGIN to your Scarletbox account.</p>
                        </div>
                        <form onSubmit={handleSubmit(onSubmitAuth)}>
                            {
                                isLogin ? (
                                    <div>
                                        <Alert show={showAlert.show} variant="danger" className="d-flex justify-content-between">
                                            <p className="mb-0">{showAlert.msg}</p> <a type="button" className="" onClick={() => setShowAlert(prevState => ({ ...prevState,show: false,msg: ''}))}>x</a> 
                                        </Alert>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group mt-0">
                                                    <label className="text-dark font-weight-bold">Email</label>
                                                    <input type="email" className="form-control" {...register("email")} name="email" placeholder="Enter your Email" required="required" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group mt-3">
                                                    <label className="text-dark font-weight-bold">Password</label>
                                                    <input type={passwordShown ? "text" : "password"}  className="form-control" {...register("password")} name="password" placeholder="Enter your Password" required="required" />
                                                </div>
                                                
                                            </div>
                                            <div className="col-12">
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className="form-group form-check">
                                                            <input type="checkbox" className="form-check-input checkboxx" id='showPassword' onClick={togglePasswordVisiblity} />
                                                            <label className="form-check-label ml-1 mt-1" for="showPassword">Show Password</label>
                                                        </div>
                                                
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className="text-right">
                                                            <a href='/auth/forget_password' target='blank'>Forgot Password</a>
                                                        </div>
                                                    </div>
                                                </div>
                        
                                            </div>
                                    
                                            <div className='col-12'>
                                                <button className="btn w-100 btn-dark" >
                                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}Login
                                                </button>
                                            </div>
                                        
                                            <div className="col-12 d-flex justify-content-center align-items-center">
                                                <hr style={{backgroundColor: "#000", margin: "auto !important", height: "2px", width: "40%", border: "none" }}/> 
                                                <span className='px-4'>OR</span> 
                                                <hr style={{backgroundColor: "#000", margin: "auto !important", height: "2px", width: "40%", border: "none" }}/>
                                            </div>
                                        
                                            <div className="col-12">
                                                <button className="btn btn-dark w-100" type="button" onClick={onClickRegister}>Register</button>
                                            </div>
                                            
                                            
                                            <div className="col-12 pt-3">
                                                <p className="text-center text-dark" style={{ fontSize: '15px' }}>*Please register if you do not have an account <a className='text-danger' onClick={onClickRegister}>here</a>.</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        {/* <Alert show={show} variant="danger">
                                            <p>
                                                {errormsg}
                                            </p>
                                        </Alert> */}
                                        <div className="row justify-content-center">
                                        
                                            <div className="col-12">
                                                <div className="form-group mt-2">
                                                    <label className="text-dark">First Name</label>
                                                    <input type="text" {...register("name")} className={`form-control`} name="name"  />
                                                    {errors.name && <p className="mt-0 text-danger">{errors.name.message}</p>}
                                                </div>
                                            </div> 
                                            <div className="col-12">
                                                <div className="form-group mt-2">
                                                    <label className="text-dark">Last Name </label>
                                                    <input type="text" {...register("last_name")} className={`form-control`} name="last_name"   />
                                                    {errors.last_name && <p className="mt-0 text-danger">{errors.last_name.message}</p>}
                                                </div>
                                            </div> 
                                        
                                            <div className="col-md-6 col-12 pl-0 d-none">
                                                <div className="form-group mt-2">
                                                    <label className="text-dark">Username &#40; Optional &#41;</label>
                                                    {/* <input type="text" className={`form-control ${ error && error.errors.username ? "is-invalid" : ""}`} name="username" onChange={ e =>setUsername(e.target.value) }  />
                                                    {error ? ( <p className="text-red m-0"> {error.errors.username} </p>) : ""} */}
                                                    <input type="text" {...register("username")} className="form-control" name="username"  />
                                                    {errors.username && <p className="mt-0 text-danger">{errors.username.message}</p>}
                                                </div>
                                            </div> 
                                            <div className="col-md-12">
                                                <div className="form-group mt-2">
                                                    <label className="text-dark">Email</label>
                                                    <input type="email" {...register("email")} className={`form-control`} name="email" required="required"/>
                                                    {errors.email && <p className="mt-0 text-danger">{errors.email.message}</p>}
                                                </div>
                                            </div> 
                                            {/* <div className="col-md-12">
                                                <div className="form-group mt-2">
                                                    <label className="text-dark">Mobile</label>
                                                    <input type="text" {...register("mobile")} className={`form-control`} name="mobile"/>
                                                </div>
                                            </div>  */}
                                            <input type="hidden" name="isRegistered" {...register("isRegistered")} value={false}/>
                                        
                                            <div className="col-12 row">
                                                <div className="form-group col-10 px-0">
                                                    <label className="text-dark">Password</label>
                                                    <input type={passwordType} {...register("password")} className={`form-control`} name="password"  required="required"/>
                                                    {errors.password && <p className="mt-0 text-danger">{errors.password.message}</p>}
                                                </div>
                                                <div className="form-group col-2 mt-4">
                                                <button type="button" className="btn btn-dark btn-smaller" onClick={togglePassword}>
                                                    { passwordType==="password"?  <i>{eyelash}</i> : <i>{eye}</i> }
                                                </button>
                                                </div>
                                            </div> 
                                        
                                            <div className="col-12 row">
                                                <div className="form-group col-10 px-0">
                                                    <label className="text-dark">Confirm Password</label>
                                                    <input type={confirmpasswordType} {...register("confirm_password")} className={`form-control`} name="confirm_password"  required="required"/>
                                                    {errors.confirm_password && <p className="mt-0 text-danger">{errors.confirm_password.message}</p>}
                                                </div>
                                                <div className="form-group col-2 mt-4">
                                                    <button type="button" className="btn btn-dark btn-smaller"  onClick={toggleConfirmPassword}>
                                                        { confirmpasswordType ==="password"?  <i>{eyelash}</i> : <i>{eye}</i> }
                                                    </button>
                                                </div>
                                            </div> 
                                            <div className="col-12">
                                                <div className="form-group mt-1 ml-3">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input " type="checkbox" name="inlineRadioOptions" id="inlineRadio1" required="required"/>
                                                        <label className="form-check-label ml-1 mt-1" htmlFor="inlineRadio1">I have read and agreed to the <a target="blank" href="https://scarletbox.io/privacy"> Terms and Conditions</a></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <button className="btn w-100 btn-dark" >
                                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}Register
                                                </button>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center align-items-center">
                                                <hr style={{backgroundColor: "#000", margin: "auto !important", height: "2px", width: "40%", border: "none" }}/> 
                                                <span className='px-4'>OR</span> 
                                                <hr style={{backgroundColor: "#000", margin: "auto !important", height: "2px", width: "40%", border: "none" }}/>
                                            </div>
                                        
                                            <div className="col-12">
                                                <button className="btn btn-dark w-100" type="button" onClick={onClickLogin}>Login</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </form>
                        
                    </Modal.Body>) : (
                    <Modal.Body style={{minHeight: '300px'}}>
                        <div className="mt-5 d-flex flex-column align-items-center">
                            <div className="spinner-border text-danger" style={{width: '6rem', height: '6rem'}} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div className="pt-3">
                                <p className="font-weight-bold">Loading...</p>
                            </div>
                        </div>
                    </Modal.Body>
                    )
                }
            </Modal>
        );
    }

    return (
        <Modal size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered 
        show={isShow}>
            <Modal.Header className="pb-0">
                <p className="font-weight-bold pt-3">Place BID</p> <button type="button" className="btn btn-dark btn-sm rounded-0 p-2" onClick={closeModal}>x</button> 
            </Modal.Header>
            {
                !isLoading ? (
                    <Modal.Body className="pt-0">
                        <div className="row p-3">
                            <div className="col-md-4">
                                <img src={art_details._merch_image} alt="scarletbox_auction" className="img-fluid"/>
                            </div>
                            <div className="col-md-8">
                                <p className="my-2 font-weight-bolder" style={{fontSize: '20px'}}>{art_details.label}</p>
                                <p className="my-2 font-weight-bold">Artist: {art_details.artist ? art_details.artist.artist_name : ""}</p>
                                <p className="my-2 " dangerouslySetInnerHTML={{ __html:art_details.artist ? art_details.art_details.description : ""}}>
                               
                                </p>

                                <form onSubmit={handleSubmit(onSubmitBid)} className="row">
                                    <div className="pl-0 col-10">
                                        <Alert show={showAlert.show} variant="success" className="d-flex justify-content-between">
                                            <p className="mb-0">{showAlert.msg}</p> <a type="button" className="" onClick={() => setShowAlert(prevState => ({ ...prevState,show: false,msg: ''}))}>x</a> 
                                        </Alert>
                                    </div>
                                    <input type="hidden" name="user_id" {...register("user_id")} value={user.id}/>
                                    <input type="hidden" name="auction_id" {...register("auction_id")} value={props.auction_id}/>
                                    <input type="hidden" name="auction_art_id" {...register("auction_art_id")} value={props.auction_art_id}/>
                                    <input type="hidden" name="source" {...register("source")} value={source}/>
                                    {
                                        highestBid ? (
                                            <div className="col-12 pl-0">
                                                <p className="mt-0"><span className="font-weight-bold">Current Highest Bid:</span> PHP {highestBid.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2,})}</p>
                                            </div>
                                        ) : ""
                                    }
                                    <div className="col-12 pl-0">
                                                <p className="mt-0"><span className="font-weight-bold">Starting Price:</span>{} PHP {props.base_price.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2,})}</p>
                                            </div>
                                    {
                                        props.auctionstatus == 1  ? 
                                        <>
                                        <div className="col-10 pl-0">
                                        <div className="form-group mt-0">
                                            <label className="text-dark font-weight-bold">Bidder Name</label>
                                            <input style={{borderRadius: '0px !important'}} {...register("bidder_name")} defaultValue={user.name + " " + user.last_name} type="text" className="form-control vip bg-light rounded-0" name="bidder_name" required />
                                            {errors.bidder_name && <p className="mt-0 text-danger">{errors.bidder_name.message}</p>}
                                        </div>
                                        </div>
                                        <div className="col-8 px-0">
                                        <div className="form-group mt-0">
                                            <input style={{borderRadius: '0px !important'}} {...register("bid_amount")} type="number" className="form-control vip bg-light rounded-0" name="bid_amount" placeholder="Amount" />
                                            {errors.bid_amount && <p className="mt-0 text-danger">{errors.bid_amount.message}</p>}
                                        </div>
                                        </div>
                                        <div className="col-4 px-1">
                                            <button style={{padding: '11px 15px'}} disabled={isSubmitting} className="btn btn-sm btn-danger rounded-0"> {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>} BID </button>
                                        </div>
                                        </> : <><span className=" text-danger text-uppercase font-weight-bold">Auction Ended</span><br></br></>
                                    }
                                    
                                    
                                </form>
                            </div>
                        </div>
                        {
                            bids.length != 0 ? (
                                <div className="row table-responsive" style={{maxHeight:"250px"}}>
                                    <table className="table table-sm table-bordered rounded border border-dark">
                                        <thead>
                                            <tr>
                                                <th scope="col">BID Amount</th>
                                                <th scope="col">BIDDER</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                bids.map((bid, id ) => {
                                                    return (
                                                        <tr key={id}>
                                                            <th scope="row">PHP {parseInt(bid.bid_amount).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2,})}</th>
                                                            <td>{bid.bidder_name}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            ) : ""
                        }
                        
                    </Modal.Body>
                ) : (
                    <Modal.Body style={{minHeight: '300px'}}>
                        <div className="mt-5 d-flex flex-column align-items-center">
                            <div className="spinner-border text-danger" style={{width: '6rem', height: '6rem'}} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div className="pt-3">
                                <p className="font-weight-bold">Loading...</p>
                            </div>
                        </div>
                    </Modal.Body>
                )
            }
        </Modal>
    );
}
 
export default ModalAuctions;