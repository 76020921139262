import React, {useState, useReducer, useEffect} from 'react';
import { useCookies } from "react-cookie";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import apiClient from "../../helpers/api";
import ArtSkeleton from '../../Skeleton/Vip/ArtSkeleton';
import { isMobile } from "react-device-detect";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ImageSkeleton from '../../Skeleton/Vip/ImageSkeleton';

const Arts = (props) => {
    const [data, setData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [checkout,setCheckout] = useState(false)
    const [artId, setArtId] = useState(0)
    const [merchImage, setMerchImag] = useState('')
    const [currentPlaying, setcurrentPlaying] = useState(0)
    const [reseverAllowedCount, setReseverAllowedCount] = useState(0)
    const [transactionType, setTransactionType] = useState(0)
    const [ignored,forceUpdate] = useReducer(x => x +1,0)
    const playIcon = <FontAwesomeIcon  icon="fa-solid fa-play" size='lg' />;
    const pauseIcon = <FontAwesomeIcon  icon="fa-solid fa-pause" size='lg'/>;
    const { handleSubmit, formState } = useForm();
    const [reserveArts, setReserveArts] = useState(['']);
    const [errorMsg, setErrorMsg] = useState('');
    const [tempUserCode, setTempUserCode] = useCookies(['temp_code']);
    const [error, setError] = useState()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [userId, setUserId] = useState('')
    const [theVipId, setTheVipId] = useState(0)
    const [totalArtist, setTotalArtist] = useState(0)
    const [counter, setCounter] = useState(1)
    const [vipData, setVipData] = useState([])
    const [selectedArtist, setSelectedArtist] = useState('')
    const [selectedLabel, setSelectedLabel] = useState('')
    const [selectedArtCode, setArtCode] = useState(0)
    const [totalArtReseverd, setTotalArtReseverd] = useState(0)
    const [loadedImage, setloadedImage] = useState([])
    const [searchKey, setSearchKey] = useState('')
    const [artistTabActive, setArtistTabActive] = useState('')
    const [noArt, setNoArt] = useState(false)
   
    const { isSubmitting} = formState;
    const [isloaded, setIsLoaded] = useState(false)
    const [isloadedart, setIsLoadedart] = useState(false)
    const playBtn = {
        position: "absolute",
        background: "rgb(0 0 0 / 30%)",
        left:"45%",
        top: '40%',
        border:"0px",
        color:"#fff"
    };
    const noBorderRadius = {
        borderRadius:"0px !important",
        
    }
    const soldOut = {
        background:"#f9f9f9"
    }
   
    

    const header = {
        background: "#222",
    }

    const body = {
        background: "#cbc7c7",
    }

    const small = {
        fontSize:"12px"
    }
    const noBorder = {
        borderRadius:"0px",
        color:"#ffffff"
    }

   
        useEffect(() => {
            if(!tempUserCode.temp_code){
                var tem_code = Math.round((new Date()).getTime() / 1000);
                var today = new Date();
                    today.setHours(today.getHours() + 720);
                setTempUserCode('temp_code',tem_code+'-'+randomNumber(), {expires: today});
            }

            try{
                apiClient.get(`${process.env.REACT_APP_API}/account/user`)
                .then(res => {
                    setName(res.data.name+' '+res.data.last_name);
                    setEmail(res.data.email)
                    setMobile(res.data.mobile)
                    setUserId(res.data.id);
                }).catch(e => {
                    
                })
            } catch(e){

            }
           
        },[]);

    useEffect(() => {
        
        apiClient.get(`${process.env.REACT_APP_API}/vip/get/reserved_arts/${tempUserCode.temp_code}`)
            .then(res => {
                setReserveArts(res.data.results) 
        }).catch( err => {
            console.log(err)
        })
 
        // if (cookies[cookieName]) {
        //     setReserveArts(cookies[cookieName])
        // }
        apiClient.get(`${process.env.REACT_APP_API}/vip/get/arts/${props.vipidc}`)
            .then(async res => {
                setTotalArtist(res.data.total_artist);
                setTheVipId(res.data.vip_id);
                setData(res.data.results);
                setReseverAllowedCount(parseInt(res.data.resever_allowed_count));
                setTransactionType(res.data.type);
                setIsLoaded(true)
                setIsLoadedart(true)
                setVipData({
                    price:res.data.price,
                    project_id: res.data.project_id,
                    free: res.data.free,
                    eth_price: res.data.eth_price,
                    sf:res.data.sf
                })
                
        }).catch( err => {
            console.log(err)
        })

        
    }, [ignored]);

    const isImageLoaded = (e) => {
        let varn = e.target.getAttribute('data-id');
        
        setloadedImage(oldFiles => [...oldFiles,varn]);
        
    }

    

    const onSearchBytab = (e) => {
        setArtistTabActive(e.target.getAttribute('data-tabid'));
        setSearchKey(e.target.value);
    }

    const vidPlay = (e) => {
        let currn = e.target.getAttribute('data-id');
        if(currentPlaying == e.target.getAttribute('data-id')){
            e.target.pause();
            setcurrentPlaying(0);
        } else {
            setcurrentPlaying(currn);
            e.target.play();
        }
       
    }

    const randomNumber = () => {
        var min = 999;
        var max = 99999;
        var rand =  min + (Math.random() * (max-min));
        return parseInt(rand);
    }

    

    const BktFresh = (e) => {

        data.map((val, id)=> { 
            document.querySelector(`#search${id}`).value = "";
        })
        setArtistTabActive('');
        setSearchKey('');
        forceUpdate()
    }

    const clearSearch = () => {

        data.map((val, id)=> { 
            document.querySelector(`#search${id}`).value = "";
        })
        setArtistTabActive('');
        setSearchKey('');
        forceUpdate()
    }
    
    const openModal = (e) => {
        setTotalArtReseverd(parseInt(e.target.getAttribute('data-reservation_')) + 1)
        setArtId( e.target.getAttribute('data-art'));
        setSelectedArtist( e.target.getAttribute('data-artist'));
        setSelectedLabel( e.target.getAttribute('data-label'));
        setArtCode( e.target.getAttribute('data-artcode'));
        setMerchImag(e.target.getAttribute('data-img'));
        setShowModal(true);
    }

    const closeModal = () => {
        setError()
        setShowModal(false);
    }

    const onReserve = (e) => {
        
        return new Promise(resolve => {
            apiClient.post(`${process.env.REACT_APP_API}/vip/process/reserve`, {
                source:props.partner,
                vip_id: theVipId,
                user_id: userId,
                art_id: artId,
                name: name,
                mobile: mobile,
                email: email,
                control_number : tempUserCode.temp_code
            }).then(res => {
                if(res.data.code == 300){
                    setErrorMsg(res.data.msg);
                    forceUpdate()
                } else if(res.data.code == 301) { 
                    setErrorMsg(res.data.msg);
                    forceUpdate()
                } else {
                    forceUpdate()
                    setError()
                    setShowModal(false);
                }
                resolve();
                
            }).catch(error => {
                if(error.response.status >= 401){
                    setError(error.response.data)
                    resolve();
                }  
            })
        });


    }

    function getDataArts(key,type = 1){
        let url = type == 1 ? `${process.env.REACT_APP_API}/vip/get/arts/${props.vipidc}` : `${process.env.REACT_APP_API}/vip/search/arts/${props.vipidc}/${key}`
        apiClient.get(url)
        .then(async res => {
            setTotalArtist(res.data.total_artist);
            setTheVipId(res.data.vip_id);
            setData(res.data.results);
            setReseverAllowedCount(parseInt(res.data.resever_allowed_count));
            setTransactionType(res.data.type);
            setIsLoadedart(true)
           
            setVipData({
                price:res.data.price,
                project_id: res.data.project_id,
                free: res.data.free,
                eth_price: res.data.eth_price,
                sf:res.data.sf
            })
            
        }).catch( err => {
            console.log(err)
        })
    }

   

    const buyNow = (e) => {
        props.stateChanger('yes')
        props.vipdata({ 
            'art_id': e.target.getAttribute('data-art'),
            'artist': e.target.getAttribute('data-artist'),
            'art_code': e.target.getAttribute('data-artcode'),
            'label':  e.target.getAttribute('data-label'),
            'free': vipData.free,
            'price':  vipData.price,
            'project_id': vipData.project_id ? vipData.project_id : e.target.getAttribute('data-project_id'),
            'eth_price': vipData.eth_price,
            'vip_id': theVipId,
            'artist_id':e.target.getAttribute('data-artist_id'),
            'reserve_id':e.target.getAttribute('data-reservation_'),
            'bundle':e.target.getAttribute('data-bundle'),
            'eth_price':e.target.getAttribute('data-eth_price'),
            'source':e.target.getAttribute('data-source'),
            'sf':vipData.sf,
            'merch' : e.target.getAttribute('data-img'),
            'art_img_type' : e.target.getAttribute('data-art_img_type'),
            'art_img' : e.target.getAttribute('data-art_img'),
        })
    }
   
    
    
    return (
        
        <div className="tab-pane fade show active" id="collection" role="tabpanel" aria-labelledby="collection-tab">

            {
                isloaded ?
                    totalArtist > 1 ?
                    <ul class="nav  nav-pills nav-justified " id="myArtTab" role="tablist" style={{display:isMobile ? 'block' : 'flex'}}>
                        {data.map((val, id)=> { 
                    return (
                        <li  className="nav-item ">
                            <a onClick={BktFresh} className={`bktnav-link  nav-link text-white ${id == 0 ? 'active' : '' }`} style={noBorder} id={`artist${id}-tab`} data-toggle="tab" href={`#artist${id}`} role="tab" aria-controls={`artist${id}`} aria-selected="true" ref={(node) => 
        node?.style.setProperty("color", "#fff", "important")
    }>{val.front_name == null ? val.artist_name : val.front_name}</a>
                        </li>
                    )
                    })
                    }
                    </ul> : '' : ''
            } 

            
            
            {isloadedart ?
            
            <div className="tab-content" id="myArtTabContent">
            {data.map((val, id)=> { 
               let xy = 0;
               {console.log(xy)}
                 return (
                <div  className={`tab-pane fade ${id == 0 ? 'show active' : '' }`} id={`artist${id}`} role="tabpanel" aria-labelledby={`artist${id}-tab`}>
                     <div className="row justify-content-end">
                        <div className="col-md-4 align-self-end ">
                        <input type="text" id={`search${id}`} className="form-control mb-2 mr-sm-2" onChange={ e => onSearchBytab(e) } placeholder="Search Art Code"></input>
                        </div>
                    </div>
                    <div class="row">
                        { 
                        searchKey != '' ?
                            <div class="col-12 col-md-12 p-2" style={soldOut}>
                                <p className='text-dark'>Result found for <b>"{searchKey}"</b> key. <a style={{cursor:'pointer'}} className='text-danger' onClick={() => clearSearch()}>Clear Search</a></p>
                            </div>
                        : ''
                        }
                        {
                            val.arts.length == 0 && searchKey == '' ? 
                            <div class="col-12 col-md-12 text-center pt-5 pb-5" style={soldOut}>
                            <h3 className='text-dark'>All Arts are Sold Out!</h3>
                            </div>
                         : ''
                        }

                    {val.arts.map((valx, idx)=> { 
                        
                        xy++;
                        if(xy > 3){  xy = 1 }
                        return (
                            
                            <div  className={isMobile ? ' col-md-4 pl-0 pr-0 my-2 ' :   xy == 1 ? ' col-md-4 pl-0 my-2 ' : xy == 3 ?  ' col-md-4 pr-0 my-2 ' : 'col-md-4 px-2 my-2 '} style={{display:valx.label.toLowerCase().indexOf(searchKey) >= 0 ? 'Block' : 'none' }}>
                                 
                            {
                                valx.extension == 'mp4' ? 
                                    <div className="image-wrapper" style={{background:"#000"}}>
                                    {
                                        !isMobile ?  <a style={playBtn}>{currentPlaying == valx.art_id ? pauseIcon : playIcon}</a> : ''
                                    }
                               
                                    <video  preload="auto" muted= {isMobile ? true : false} autoPlay= {isMobile ? true : false} controls= {isMobile ? true : false} playsInline loop onClick={e => vidPlay(e)} data-id={valx.art_id} id={`video${valx.art_id}`}  className="embed-responsive-item " src={valx.uri} style={{width: "100%",height:"380px"}} ></video>
                                    </div>
                               //isImageLoaded
                                 : <>
                                    <ImageSkeleton  style_={loadedImage.indexOf(valx.uri) >= 0 ? "none" : "block"}/>
                                    <img onLoad={ (e) => isImageLoaded(e)} style={{display:loadedImage.indexOf(valx.uri) < 0 ? "none" : "block"}} data-id={valx.uri} src={valx.uri} className='img-fluid'></img>
                                   </>
                            }
                            <p className='text-dark my-1 text-center font-weight-light' dangerouslySetInnerHTML={{__html: 'Title : '+valx.label}}></p>
                            {
                                transactionType == 1 ?
                                    //valx.reservation > 0 ?
                                        reseverAllowedCount <= valx.reservation_
                                        ? <button className='btn btn-sm text-uppercase btn-dark text-white btn-block' style={{borderRadius: 0,fontWeight:'normal'}}>Reserved</button>
                                        : 
                                            reserveArts.indexOf(valx.art_id) >= 0 ?
                                            <button className='btn btn-sm text-uppercase btn-dark text-white btn-block' style={{borderRadius: 0,fontWeight:'normal'}}>Reserved</button>
                                            :
                                            <button onClick={e => openModal(e)} data-artist={val.artist_name} data-label={valx.label} data-artcode={valx.art_code} data-reservation_={valx.reservation_} data-art={valx.art_id} data-img={valx.merch} className='btn btn-sm text-uppercase btn-danger btn-block' style={{borderRadius: 0,fontWeight:'normal'}}> I want to reserve this</button>
                                       
                                    //: <button className='btn btn-sm btn-danger btn-block' style={{borderRadius: 0}}>I want to reserve this</button>
                                :
                                valx.fake_status == null  ?
                                <button onClick={e => buyNow(e)} data-project_id={val.project_id} data-art_img_type={valx.extension} data-art_img={valx.uri} data-sf={val.sf} data-source={props.partner} data-eth_price={val.eth_price}   data-bundle={val.is_bundle} data-reservation_={valx.reservation_} data-artist_id={valx.artist_id} data-artist={val.artist_name} data-label={valx.label} data-artcode={valx.art_code}  data-art={valx.art_id} data-img={valx.merch}  className='btn btn-sm btn-danger btn-block' style={{borderRadius: 0}}>Collect Now</button>
                                : <button className='btn btn-sm text-uppercase btn-dark text-white btn-block' style={{borderRadius: 0,fontWeight:'normal'}}>{valx.fake_status }</button>
                            }
                            </div>
                            
                            )
                           
                        })
                      
                    }
                    </div>
                </div>
                )
                })
            }
            </div>
             : <div class="row"><ArtSkeleton arts={6}/></div> }
            <>
            <Modal show={showModal} style={noBorderRadius}
                size={ merchImage != '' && merchImage != null ? 'xl' : 's'}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="vip-modal"
                className="px-3"
            >
                <Modal.Header style={header}>
                    <h5 className="modal-title text-white"></h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                        <span aria-hidden="true" className="text-white">X</span>
                    </button>
                </Modal.Header>
                <Modal.Body style={body}>
                    <div className="row p-3">
                                {
                                    merchImage != '' && merchImage != null ?
                                    <div className="col-12 col-md-6">
                                    <img
                                    className="img-fluid"
                                    src={merchImage}
                                    alt=""
                                   
                                />
                                </div>
                                : ''
                                }
                               
                                <div className={ merchImage != '' && merchImage != null ? 'col-12 col-md-6' : 'col-12 col-md-12'}>
                                 
                                    <p>
                                        
                                        <b>Title : </b> <span className='float-right text-right' dangerouslySetInnerHTML={{__html: selectedLabel}}></span> <br></br>
                                        <br></br><b>Art Code :</b> <span className='float-right'>{selectedArtCode}</span><br></br>
                                        <b>Artist :</b> <span className='float-right'>{selectedArtist}</span><br></br>
                                        {
                                            reseverAllowedCount > 1 ? 
                                            <>You are no. {totalArtReseverd} to reserve this art <br></br></> : ''
                                        }
                                    </p> 
                                    {
                                        errorMsg ?
                                        <div class="alert alert-danger">{errorMsg}</div>
                                        : ''
                                    }
                                <form id="vip-form" onSubmit={handleSubmit(onReserve)}  className="">
                               
                                    <div className="form-group mt-2">
                                        <input value={name} type="text" placeholder="First Name" className={`form-control vip ${ error && error.errors.name ? "is-invalid" : ""}`} name="name" onChange={ e =>setName(e.target.value) } style={{borderRadius:"0px"}} />
                                        {error ? ( <p className="text-red m-0"> {error.errors.name} </p>) : ""}
                                    </div>
                                    <div className="form-group mt-2">
                                        <input  value={email} type="text" placeholder="Email" className={`form-control vip ${ error && error.errors.email ? "is-invalid" : ""}`} name="email" onChange={ e =>setEmail(e.target.value) } style={noBorderRadius} />
                                        {error ? ( <p className="text-red m-0"> {error.errors.email} </p>) : ""}
                                    </div>

                                    <div className="form-group mt-2">
                                        <input value={mobile}  type="text" placeholder="Mobile" className={`form-control vip ${ error && error.errors.mobile ? "is-invalid" : ""}`} name="mobile" onChange={ e =>setMobile(e.target.value) } style={noBorderRadius} />
                                        {error ? ( <p className="text-red m-0"> {error.errors.mobile} </p>) : ""}
                                    </div>
                                    <button className="btn btn-dark btn-block mb-2" type="submit" style={noBorderRadius}>{isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        RESERVE NOW
                                    </button>
                                    <small style={small}>{props.disclaimer}</small>
                                     
                                </form>
                                </div>
                        </div>
                </Modal.Body>
            </Modal>
            </>
         
        </div> 
    );

};
export default Arts;
