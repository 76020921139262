import React, {useState, useEffect} from "react";
import { Modal } from "react-bootstrap";
import apiClient from "../../helpers/api";
import { useForm } from "react-hook-form";
import Select from 'react-select';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;
const eyelash = <FontAwesomeIcon icon="fa-solid fa-eye-slash" />;



const RegisterPopup = (props) => {

    const { params } = props;
    const { handleSubmit, formState } = useForm();
    const [show, setShow] = useState(false)
    const [country, setCountry] = useState()
    const [data, setData] = useState({})
    const [error, setError] = useState()

    //input fields
    const [username, setUsername] = useState('')
    const [name, setName] = useState('')
    const [last_name, setLastname] = useState('')
    const [mobile, setMobile] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirm_password, setConfirmPassword] = useState('')
    const [twitter, setTwitter] = useState('')
    const [discord, setDiscord] = useState('')
    const [country_selected, setCountrySel] = useState()
    const [referal_code, setReferalCode]  = useState()
    const [isScroll, setIsScroll] = useState(false)
    const [terms, setTerms] = useState('')
    
    const { isSubmitting} = formState;

    const [passwordType, setPasswordType] = useState("password");
    const [confirmpasswordType, setConfirmPasswordType] = useState("password");

  
    const togglePassword =()=>{
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
      }

    const toggleConfirmPassword =()=>{
        if(confirmpasswordType==="password")
        {
            setConfirmPasswordType("text")
         return;
        }
            setConfirmPasswordType("password")
      }


  
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmpasswordShown, setConfirmPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };

    const toggleConfirmPasswordVisiblity = () => {
        setConfirmPasswordShown(confirmpasswordShown ? false : true);
      };

   
    
    const onRegister = (e) => {

        return new Promise(resolve => {
            apiClient.post(`${process.env.REACT_APP_API}/auth/register`, {
                username: username,
                name: name,
                last_name: last_name,
                mobile: mobile,
                email: email,
                password: password,
                confirm_password: confirm_password,
                twitter: twitter,
                discord: discord,
                country_code: country_selected,
                referal_code: referal_code
            }).then(res => {
                setIsScroll(false)
                setData(res.data)
                setShow(true)
                resolve(); 
            }).catch(error => {
                if(error.response.status >= 401){

                    setError(error.response.data)
                    console.log(error);

                    var url = window.location.href
                    var scroll = url.includes("/#")
                
                    if(scroll){
                        setIsScroll(true)
                    }
                    document.querySelector('#register-form').parentElement.scrollIntoView({behavior: "smooth"})

        
                    resolve();
                }  
            })
        })

    };



    useEffect( () => {
        apiClient.get(`${process.env.REACT_APP_API}/geo-location/country`).then(res => {
           setCountry(res.data.data)
        })
        
    },[])

    let options = country && country.map(val => {
        return  { value: val.id, label: `${val.name} (${val.phonecode})` };
    })


    return (
    <section className="author-area py-0 px-lg-5">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 p-0 m-0">
                {/* Intro */}
                <div className="intro text-center" id="regform">
                    <h2 className="text-dark">{props ? props.title : ""}</h2>
                    {/* <div className="text-dark" dangerouslySetInnerHTML={{__html: params ? params.sub_title : ""}}></div> */}
                </div>
                {/* Item Form */}
                <form id="register-form" onSubmit={handleSubmit(onRegister)} className="">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-sm-12">
                            <div className="row align-items-center">
                                <div className="col-md-6 col-12 pl-0">
                                    <div className="form-group mt-2">
                                        <label className="text-dark">First Name</label>
                                        <input type="text" className={`form-control ${ error && error.errors.name ? "is-invalid" : ""}`} name="name" onChange={ e =>setName(e.target.value) }  />
                                        {error ? ( <p className="text-red m-0"> {error.errors.name} </p>) : ""}
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 pl-0">
                                    <div className="form-group mt-2">
                                        <label className="text-dark">Last Name </label>
                                        <input type="text" className={`form-control ${ error && error.errors.last_name ? "is-invalid" : ""}`} name="last_name" onChange={ e =>setLastname(e.target.value) }  />
                                        {error ? ( <p className="text-red m-0"> {error.errors.last_name} </p>) : ""}
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="col-lg-10 col-sm-12">
                            <div className="row align-items-center">
                                <div className="col-md-6 col-12 pl-0 d-none">
                                    <div className="form-group mt-2">
                                        <label className="text-dark">Username &#40; Optional &#41;</label>
                                        {/* <input type="text" className={`form-control ${ error && error.errors.username ? "is-invalid" : ""}`} name="username" onChange={ e =>setUsername(e.target.value) }  />
                                        {error ? ( <p className="text-red m-0"> {error.errors.username} </p>) : ""} */}
                                        <input type="text" className="form-control" name="username" onChange={ e =>setUsername(e.target.value) }  />
                                    </div>
                                </div> 
                                <div className="col-md-12 col-12 pl-0">
                                    <div className="form-group mt-2">
                                        <label className="text-dark">Email</label>
                                        <input type="email" className={`form-control ${ error && error.errors.email ? "is-invalid" : ""}`} name="email"onChange={ e =>setEmail(e.target.value) } />
                                        {error ? ( <p className="text-red m-0"> {error.errors.email} </p>) : ""}
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="col-lg-10 col-sm-12">
                            <div className="row align-items-center">
                                <div className="col-12 col-lg-6 px-0">
                                    <div className="row align-items-center">
                                        <div className="col-10 pl-0 pr-2">                                           
                                            <div className="form-group mt-2">
                                                <label className="text-dark">Password</label>
                                                <input type={passwordType} className={`form-control ${ error && error.errors.password ? "is-invalid" : ""}`} name="password" onChange={ e =>setPassword(e.target.value) }   />
                                                {error ? ( <p className="text-red m-0"> {error.errors.password} </p>) : ""}
                                            </div>
                                        </div> 
                                        <div className="col-md-1 col-2 pl-0 ml-0  mt-3 ">
                                            <div className="pt-1">
                                                <button type="button" className="btn btn-dark btn-smaller" onClick={togglePassword}>
                                                    { passwordType==="password"?  <i>{eyelash}</i> : <i>{eye}</i> }

                                                </button>

                                            </div>
                                        </div>
                                        </div>
                                </div>
                                <div className="col-12 col-lg-6 px-0">
                                    <div className="row align-items-center">
                                        <div className="col-10 pl-0 pr-2">
                                            <div className="form-group mt-2">
                                                <label className="text-dark">Confirm Password</label>
                                                <input type={confirmpasswordType} className={`form-control ${ error && error.errors.confirm_password ? "is-invalid" : ""}`} name="confirm_password" onChange={ e =>setConfirmPassword(e.target.value) }  />
                                                {error ? ( <p className="text-red m-0"> {error.errors.confirm_password} </p>) : ""}
                                            </div>
                                        </div> 
                                        
                                        <div className="col-md-1 col-2 pl-0 ml-0 mt-3 ">
                                            <div className="pt-1">
                                                <button type="button" className="btn btn-dark btn-smaller"  onClick={toggleConfirmPassword}>
                                                { confirmpasswordType ==="password"?  <i>{eyelash}</i> : <i>{eye}</i> }
                                                </button>
                                            </div>
                                        </div>
                                    </div>   
                                </div> 
                            </div>
                        </div> 
                       
                        <div className="col-10">
                            <div className="form-group mt-1 ml-3">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input " type="checkbox" name="inlineRadioOptions" id="inlineRadio1" onChange={ e =>setTerms(e.target.value) }/>
                                    <label className="form-check-label pt-2" htmlFor="inlineRadio1">I have read and agreed to the <a target="blank" href="https://scarletbox.io/privacy">Terms and Conditions</a></label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <button className="btn btn-dark mt-3 mt-sm-4 text-center px-5" type="submit">{isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            LET'S DO THIS!</button>
                        </div>
                        {/* <div className="col-12">
                            <p className="d-block text-center mt-4 text-dark">Already have an account? 
                                <a className="text-red px-1" href="" data-toggle="modal" data-target="#modal-login">Login</a>
                            </p>
                        </div> */}
                
                    </div>
                </form>
                <Modal
                            show={show}
                            onHide={() => setShow(false)}
                            dialogClassName="modal-90w"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Body>
                            
                                { data.code == 300 ? (
                                        <div className="bg-lwhite">
                                                <p className="text-center text-dark">{data.msg}</p>
                                                    <br/>
                                            <button className="btn btn-primary btn-block" onClick={() => { setShow(false)}}>CLOSE</button>
                                        </div>
                                    ): (
                                        <div className="bg-lwhite">
                                                <h5 className="text-center text-dark normal-font">ALMOST THERE!</h5>
                                                <p className="text-center  text-dark">
                                                    Registration successful.
                                                    <br/>
                                                    Please log in using your email address and password to purchase your Larry Alcala digital collectible.
                                                    <br/>
                                                    1. Click Collections
                                                    <br />
                                                    2. Click Larry Alcala
                                                </p>
                                            <br/>
                                            <button className="btn btn-primary btn-block" onClick={() => { window.location.reload()}}>CLOSE</button>
                                        </div>
                                    ) 
                                }
                               
                            </Modal.Body>
                        </Modal>
            </div>
            </div>
        </div>
    </section>
                    
    );

}

export default RegisterPopup;