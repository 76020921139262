import React, { Component } from "react";
import { Route } from "react-router-dom";

// pages import
import AuctionPage from "../pages/Auction/AuctionPage";
import ItemDetail from "../pages/Items/ItemDetails";

import PrivateSale from "../pages/Sale/PrivateSale";
import PublicSale from "../pages/Sale/PublicSale";
import SinglePage from "../page/Single";
import HomePage from "../page/Home";
import Register from "../pages/Register/Register";
import WhatIsNft from "../pages/WhatIsNft/WhatIsNft";
import CheckoutBox from "../pages/Checkout/CheckoutBox";
import PageNotFound from "../components/PageNotFound/PageNotFoundPage";
import Details from "../pages/CollectionDetails/Details";
import MyAccountPage from "../page/account/MyAccountPage";
import Paymongo from "../pages/Payment/Paymongo";
import Crypto from "../pages/Payment/Crypto";
import Sbx from "../pages/Admin/Sbx";
import MintTransfer from "../pages/Admin/MintTransfer";
import BulkRevealPage from "../pages/BulkReveal/BulkRevealPage";
import CollectionPage from "../pages/CollectionDetails/CollectionPage";
import ViewAuctionPage from "../pages/Auction/ViewAuctionPage";

import Collection from "../pages/CollectionV2/Collection";
import RoadMapPage from "../pages/RoadMap/RoadMapPage";

import Vip from "../pages/Vip/Vip";
class PrivateRoutes extends Component{
    render() {
        return ( 
          <>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/register" component={Register}/>
              <Route exact path="/what-is-nft" component={WhatIsNft} />
              <Route exact path="/sale/auctions" component={AuctionPage} />
              <Route exact path="/sale/auctions/:id" component={ViewAuctionPage} />
              <Route exact path="/auth/my-account" component={MyAccountPage}/>
              <Route exact path="/:url/sale/private-sale" component ={PrivateSale}/>
              <Route exact path="/:url/sale/public-sale" component={PublicSale}/>
              <Route exact path="/account/collection-details" component={PageNotFound}/>
              <Route exact path="/account/collection-details/:id/:art_id" component={Details}/>
              <Route exact path="/account/collections" component={CollectionPage}/>
              <Route exact path="/account/bulk-reveal" component={BulkRevealPage}/>
              <Route exact path="/checkout" component={CheckoutBox}/>
              <Route exact path="/thank-you/:code" component={Paymongo} />
              <Route exact path="/thank/:code" component={Crypto} />
              <Route exact path="/admin/sbx" component={Sbx} />
              <Route exact path="/admin/minttrasfer/:id" component={MintTransfer} />
              <Route exact path="/collection-projects/view/:url" component={Collection} />
              <Route exact path="/vip/:url/:code" component={Vip} />
              <Route exact path="/auctions/:url/:code" component={AuctionPage} />
              <Route exact path="/collection-projects/road-map" component={RoadMapPage}/>

              <Route exact path="/:url" component={SinglePage} />

              {/* <Route component={PageNotFound} />  */}
          </>
        );
      }
}

export default PrivateRoutes;