import React, {Component} from "react";

class Resources extends Component {
    render() { 
        return (
           <>
           <div className="container py-5 px-3 text-justify text-black">
                <div className="row justify-content-center mt-3 mb-3">
                {
                    this.props.params.map((res, id)=> {
                    return (
                        <div className="col-md-3 text-center mb-4 mb-md-0">
                            <a href={res.link} target="_blank" rel="noopener">
                            <img class="img-fluid" src={res.url} alt=""/> 
                            </a>
                            <p className="text-dark mt-2">{res.description}</p> 
                        </div>
                    )
                    })
                }
                </div>
           </div>
           </>
        );
    }
}
 
export default Resources;