import React, {Component, useState, useEffect} from "react";
import apiClient from '../../helpers/api';

import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import PageLoading from "../FullPageLoading/PageLoading";
import ModalArtist from "../Modal/ModalArtist";

const ArtistList = (props) => {
    console.log(props.params);
    const [currentId, setCurrentId] = useState(0);

    const active = {
        color: "white",
        backgroundColor: '#333',
        padding: "10px",
        cursor:'pointer'
    };

    const inactive = {
        cursor:'pointer',
        padding: "10px",
    };

    const artistFont = {
        fontSize:'12px'
    };

    const selectProject = (e) => {
        let id = e.currentTarget.getAttribute('data-id');
        setCurrentId(id);
    }

    return(
        <>
            <div className="container py-5 px-3 text-justify text-black">
                <div className="row  mt-3 mb-3">
                    <div className="col-md-3  mb-4 mb-md-0">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item" data-id="0" onClick={selectProject} style={currentId == 0 ? active : inactive}>All Collections</li>
                            {
                                props.params.filters.map((res, id)=> {
                                    return (
                                            <li data-id={res.id} class="list-group-item" onClick={selectProject} style={currentId == res.id ? active : inactive}>{res.project_name}</li>
                                        )
                                })
                            }
                        </ul>
                    </div>

                    <div className="col-md-9 text-center mb-4 mb-md-0">
                        <div className="row mb-3">
                        {
                                props.params.artists.map((res, id)=> {
                                return (
                                    <>
                                    {
                                        currentId == 0 || currentId == res.project_id ?
                                        <>
                                    <div key={id} id={res.artist.id} className="col-lg-3 col-md-6  item d-flex flex-column align-items-center justify-content-between mt-2" >
                                    
                                            
                                    <div className="image-over mb-1">
                                        <img className="card-img-top h-100 img-fluid" src={window.photo_url+res.artist.photo} alt="" loading="lazy"/>
                                    </div>
                                    <div>
                                    <h3 className="artist_name text-dark text-center normal-font text-uppercase mt-0 mb-0 " style={artistFont}>{res.artist.artist_name}</h3>
                                    {res.artist.sub_heading ? <p className="mb-1 text-dark text-center normal-font text-uppercase mt-0" style={{fontSize:'8px'}}>{res.artist.sub_heading}</p> : <p className="mb-1 text-dark text-center normal-font text-uppercase mt-0">&nbsp;</p>}
                                    </div>
                                
                                    {/* <div className="col-12 text-left text-truncate pb-3 text-white" style={{height: "40px"}} dangerouslySetInnerHTML={{__html: res.artist.description}}></div> */}
                                    <button type="button" className="btn btn-smaller btn-dark font-weight-normal" data-toggle="modal" data-target={`#modal-artist-${res.artist_id}`}>Learn More</button>
                                    
                                    
                                
                                </div>
                                <ModalArtist content={ res } />
                                </>
                                : ''
                            }
                                    </>
                                    
                                )
                                })
                        }
                        </div>
                    </div>
                    
                </div>
            </div>
            </>
    ) 
   
}

export default ArtistList;
