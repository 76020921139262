import React, {useState, useEffect} from "react";
import apiClient from "../../helpers/api";
import PageLoading from "../FullPageLoading/PageLoading";
import { Link } from "react-router-dom";
import swal from 'sweetalert';

const MyCollections = () => {

    const [collection, setCollection]        = useState([])
    const [type, setType]                    = useState('')
    const [mint, setMint]                    = useState()
    const [loading, setLoading]              = useState(true)
    const [loading_filter, setLoadingFilter] = useState(true)
    const [current_page, setCurrentPage]     = useState(1)
    const [total, setTotal]                  = useState('')
    const [public_sale, setPublicSale]       = useState([])
    const [private_sale, setPrivateSale]     = useState([])
    const [auction, setAuction]              = useState([])
    const [projects, setProjects]            = useState()
    const [rarity, setRarity]                = useState()
    const [bundleUnmited, setBundleUnmited]  = useState()
    const [sel_project, setSelProject]       = useState()
    const [sel_rarity,  setSelRarity]        = useState()
    const [wallet,  setWallet]        = useState('')
    const handleNextPage = () => {
        setCurrentPage(current_page + 1)
    }

    const handlePrevPage = () => {
        setCurrentPage(current_page - 1)
    }

    // const getPublicSales = () => {
    //     apiClient.get('/account/user')
    //     .then(res => {
    //         apiClient.get(`/account/collections/lists/${res.data.id}/1`)
    //             .then(res => {
    //                 setPublicSale(res.data.results)
    //             })
    //     }).catch(e => {
    //         console.log(e);
    //     })
    // }

    // const getPrivateSales = () => {
    //     apiClient.get('/account/user')
    //     .then(res => {
    //         apiClient.get(`/account/collections/lists/${res.data.id}/2`)
    //             .then(res => {
    //                 setPrivateSale(res.data.results)
    //             })
    //     }).catch(e => {
    //         console.log(e);
    //     })
    // }
    
    // const getAuctions = () => {
    //     apiClient.get('/account/user')
    //     .then(res => {
    //         apiClient.get(`/account/collections/lists/${res.data.id}/3`)
    //             .then(res => {
    //                 console.log(res);
    //                 setAuction(res.data.results)
    //             })
    //     }).catch(e => {
    //         console.log(e);
    //     })
    // } 

    const getTotal = () => {
        apiClient.get('/account/user')
        .then(res => {
            setWallet(res.data.wallet)
            apiClient.get(`/account/collections/lists/${res.data.id}`)
                .then(res => {
                     
                    setTotal(res.data.results.length)
                })
        }).catch(e => {
            console.log(e);
        })
    }

    const getBundleUnmitedTotal = () => {

        apiClient.get('/account/user')
        .then(res => {
            apiClient.get(`/account/collections/un-minted-bundle/${res.data.id}`)
                .then(res => {
                   
                    setBundleUnmited(res.data)
                })
        }).catch(e => {
            console.log(e);
        })
   

    }

    const getProjects = () => {
        apiClient.get('/account/user')
        .then(res => {
            apiClient.get(`/account/collections/projects/${res.data.id}`)
                .then(res => {
                    setProjects(res.data.project)
                    setRarity(res.data.rarity)
                })
        }).catch(e => {
            console.log(e);
        })
    }
    
    useEffect(() => {
        // getPublicSales()
        // getPrivateSales()
        // getAuctions()
        getTotal()
        getBundleUnmitedTotal()
    }, [])

    

    useEffect(()=>{
        setLoadingFilter(true)
        
        apiClient.get('/account/user')
        .then(res => {
            apiClient.get(`/account/collections/lists/${res.data.id}/${type}`,{
                params: {
                    mint: mint,
                    rarity: sel_rarity,
                    project: sel_project,
                    page: current_page
                }
            })
                .then(res => {
                    console.log(res);
                    setCollection(res.data)
                    setLoading(false)
                    setLoadingFilter(false)
                    getProjects()

                    try{
                        document.querySelector('.item-details-area').parentElement.scrollIntoView()
                    } catch(e){
                        
                    }
                   
                })
        }).catch(e => {
            console.log(e);
        })
        

    }, [type,current_page, mint, sel_project, sel_rarity])

    const filterPill = () => {
        switch (type) {
            case 1:
                return <span className="badge badge-danger">Private Sale</span>
            case 2:
                return <span className="badge badge-danger">Public Sale</span>
            case 3:
                return <span className="badge badge-danger">Auction</span>
            case 4:
                return <span className="badge badge-danger">Special Collection</span>
            default:
                return ""
                break;
        }
    }

    if (loading) {
      return <PageLoading/>
    }

    if (total === 0 ) {
        return (
            <div className='container'>            
                <div className='container'>
                    <div className='row'>
                        <section className="faq-area pt-5">
                            <div className="container">
                                <div className="row justify-content-md-center">
                                    <div className="col-md-8 ">
                                        <h4 className="text-center text-dark normal-font">
                                                No Collections Yet
                                            </h4>
                                        <div className="row justify-content-md-center">
                                        <div className="col-md-5 ">
                                            
                                        <img src={window.photo_url+'private_.jpg'} className="" />
                                        </div>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>  
                </div>
            </div>
        );
    }

    return (
    
        <section className="item-details-area pt-5">
            <div className="container">
                {
                    (total != 0 ) ? (
                        <div className='col-md-12'>

                            {bundleUnmited > 0 ? 
                            wallet ?
                            <div className="py-2">
                                <div className="row justify-content-end">
                                    <div className="col-md-12 rounded border">
                                        <p className="py-3 mb-2">
                                            You can now reveal all your NFT arts <a href="/account/bulk-reveal">here</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="py-2">
                                <div className="row justify-content-end">
                                    <div className="col-md-12 red-border">
                                        <p className="py-3 mb-2">
                                        You can now reveal your nft, please link your Metamask wallet to your account  <a href="/auth/my-account">here</a><br></br>
                                        Once you link your Metamask wallet you can now mint/reveal your arts after 24hrs.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            : ''}

                            <div className="py-2">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label className="" for="exampleFormControlSelect1">Project</label>
                                            <select className="form-control" onChange={(e) => {setSelProject(e.target.value)}} id="exampleFormControlSelect1">
                                                <option value="" selected>All</option>
                                                {
                                                    projects && projects.map(res => {
                                                        return (
                                                            <option key={res.id} value={res.id}>{res.project_name}</option>
                                                        )
                                                    })
                                                }
                                                
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label className="" for="exampleFormControlSelect1">Rarity</label>
                                            <select className="form-control" onChange={(e) => {setSelRarity(e.target.value)}} id="exampleFormControlSelect1">
                                                <option value="" selected>All</option>
                                                {
                                                    rarity && rarity.map(res => {
                                                        return (
                                                            <option key={res.id} value={res.id}>{res.rarity}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label className="" for="exampleFormControlSelect1">Type</label>
                                            <select className="form-control" onChange={(e) => {setType(e.target.value)}} id="exampleFormControlSelect1">
                                                <option value="" selected>All</option>
                                                <option value="1">Private</option>
                                                <option value="2">Public</option>
                                                <option value="3">Auction</option>
                                                <option value="4">Special Collection</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label className="" for="exampleFormControlSelect1 ">Status</label>
                                            <select className="form-control" onChange={(e) => {setMint(e.target.value)}} id="exampleFormControlSelect1">
                                                <option value='' selected>All</option>
                                                <option value='0'>For Minting</option>
                                                <option value='1'>Minted</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p classname="text-danger" style={{color: "red", margin: "0 0 0 1rem"}}>Note: Pls click the image to access your download button of a hi-resolution copy for your private use.</p>
                            </div>
                    ) : ("")
                }
                
                
                <div className='col-md-12 mt-4'>
                    <div className="row">
                    {
                        loading_filter ? (
                            <div className="w-100 d-flex justify-content-center">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <p className="ml-2 pt-0 mt-1 font-weight-bold">Loading...</p>
                          </div>
                        ) : (
                            collection.results.length > 0 ? (
                                collection && collection.results.map(res => {
                                    return (
                                        <div key={res.id} className={collection.results.length > 2 ? "col-md-6 col-xl-3 mb-3" : "col-md-4  mb-3 "}>
                                            <div className="h-100 rounded border border-dark p-0 d-flex justify-content-between flex-column">
                                                    <Link to={`/account/collection-details/${res.id}/${res.art_id}`}>
                                                    <div className="image-wrapper" style={{width: "100%", height: '254px'}}>
                                                        {
                                                            res.sale_type_id == 4 ? 
                                                                <>
                                                                 {
                                                                res.art_special_img_type == 'mp4' ? 
                                                                <video className="embed-responsive-item "  style={{width: "100%", height: '254px'}} src={res.art_special_img}></video>
                                                                : 
                                                                <img src={res.art_special_img} className=" mx-auto d-block" style={{height: "100%",width:"100%", objectFit:"contain"}} alt={res.project}/>
                                                                }
                                                                </>
                                                            :
                                                            res.project_mint == 2 && res.tr_hash != null && res.art_uri != null ?
                                                            <>
                                                            {
                                                                res.extension == 'mp4' ? 
                                                                <video className="embed-responsive-item " style={{width: "100%", height: '254px'}}  src={res.art_uri}></video>
                                                                : 
                                                                <img src={res.art_uri} className=" mx-auto d-block" style={{height: "100%",width:"100%", objectFit:"contain"}} alt={res.project}/>
                                                            }
                                                            </>
                                                        
                                                            : res.show_orig_art == 2 ? 
                                                                res.extension == 'mp4' ? 
                                                                <video className="embed-responsive-item " style={{width: "100%", height: '254px'}} src={res.art_uri}></video>
                                                                : 
                                                                <img src={res.art_uri} className=" mx-auto d-block" style={{height: "100%",width:"100%", objectFit:"contain"}} alt={res.project}/>
                                                            :
                                                            <img src={res.art_foto} className=" mx-auto d-block" style={{height: "100%",width:"100%", objectFit:"contain"}} alt={res.project}/>
                                                            
                                                        }
                                                    </div>
                                                    </Link>
                                                    <div className="p-3">
                                                        <Link to={`/account/collection-details/${res.id}/${res.art_id}`}>
                                                            <h5 className="m-0 pt-2 font-family-normal text-dark">{res.art_name}</h5>
                                                            <h5 className="m-0 pt-2 font-family-normal text-dark">{res.project}</h5>
                                                        </Link>
                                                        <p className="m-0 ">{res.artist}</p>
                                                        <p className="m-0 "> <strong >Mint Status: </strong>{res.mint_status}</p>
                                                        <p className="m-0 "><strong>Sale Type: </strong> {res.sales_type}</p>
                                                        <p className="m-0 "><strong>TR #: </strong> {res.tr_no}</p>
                                                    

                                                        {
                                                        res.tr_hash != null ?
                                                        <>
                                                        <a href={res.ether_scan} target="_blank"  >View on Etherscan</a><br></br>
                                                        <a href={res.opensea} target="_blank" >View on Opensea</a>
                                                        </>
                                                        : res.sale_type_id == 4 ? <><a href={res.special_url} target="_blank"  >View on Etherscan</a><br></br></> : ''
                                                        }
                                                    </div>
                                            </div>
                                            
                                        </div>
                                    )
                                })) : (
                                    <section className="item-details-area pt-5">
                                        <div className="container xbtk">
                                            <div className="card">
                                                <h2 className="mx-auto">No Collection found.</h2>
                                            </div>
                                        </div>
                                    </section>
                            )
                        )
                    }
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <button className={current_page === 1 ? `btn btn-dark d-none` : `btn btn-dark mr-4`} onClick={handlePrevPage}> Previous </button>
                            <button className={collection.results.length < 10 ? `btn btn-dark d-none` : `btn btn-dark mr-4`} onClick={handleNextPage}> Next </button>
                        </div>
                    </div>
                </div>
                
                
            </div>
       </section>
           
   );

}

export default MyCollections