import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import ArtSkeleton from '../../Skeleton/Vip/ArtSkeleton';
const TabSkeleton = () => {
    return (
        <div className="row items d-flex justify-content-center pb-5">
            <div className="col-md-12 pl-0">
            <ul class="nav  nav-pills nav-justified " id="myTab" role="tablist">
                <li className="nav-item pr-2">
                   <Skeleton height={50}/>
                </li>
                <li className="nav-item pr-2">
                <Skeleton height={50}/>
                </li>
                <li className="nav-item ">
                    <Skeleton height={50}/>
                </li>
            </ul>
            </div>
            <ArtSkeleton arts={6}/>
        </div>
    )
}

export default TabSkeleton