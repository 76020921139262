import React, { useEffect, useState} from 'react';
import AuctionItem from './AuctionItem';
import apiClient from '../../helpers/api';
import AuctionItemSkeleton from '../../Skeleton/Auctions/AuctionItemSkeleton';
import { Alert, Modal } from 'react-bootstrap';
import ModalAuctions from '../Modal/ModalAuctions';

const Auction = (props) => {

    const [isLoaded, setIsLoaded]  = useState(false)
    const [auction_arts, setAuctionArts] = useState([]);
    const [isShow, setIsShow] = useState(false);
    const [modalType, setModalType] = useState('auction_bids');
    const [auction_id, setAuctionId] = useState(null);
    const [auction_art_id, setAuctionArtId] = useState(null);
    const [showMerch, setShowMerch] = useState(false);
    const [merchImage, setMerchImage] = useState('');
    const [searchKey, setSearchKey] = useState('')

    const [artBasePrice, setArtBasePrice] = useState(0)

    const btn_outline_red = {
        border: '1px solid #c31b01',
        backgroundColor: '#fffff',
        color: '#c31b01'
    }

    const soldOut = {
        background:"#f9f9f9"
    }

    const getAuctionArts = () => {
       
        try {
            apiClient.get(`${process.env.REACT_APP_API}/auctions/get/auction_arts/${props.auction_url}/`).then(res => {
                
                setAuctionArts(res.data.result)
                setIsLoaded(true)
            }).catch( err => {
                
                setAuctionArts([])
                setIsLoaded(true)
            })
        } catch (error) {
            setAuctionArts([])
            setIsLoaded(true)
        }
    }

    const verifyLogin = async () => {
        try {
            const res = await apiClient.get(`${process.env.REACT_APP_API}/account/user`);
            if (res.status == 200) {
                setModalType('auction_bids')
                return true;
            } 
        } catch (error) {
            setModalType("login")
            return false;
        }
    }

    const placeBids = (art) => {
        verifyLogin()
        setAuctionId(art.auction_id)
        setAuctionArtId(art.id)
        setArtBasePrice(art.bid_start);
        setIsShow(true)
    }

    const closeModal = () => {
        setAuctionId(null)
        setAuctionArtId(null)
        setIsShow(false)
        setShowMerch(false)
    }

    const searchArts = (e) => {
        console.log(e.target.value);
        setSearchKey(e.target.value)
    }
    
    const clearSearch = () => {
        document.querySelector('#search_form').value = ""
        setSearchKey('');
    }

    const viewMerch = (art) => {
        setMerchImage(art.merch_image)
        setShowMerch(true)
        console.log('test');
    }
    
    useEffect(() => {
        getAuctionArts();
    }, []);

    
  
    
    return (
        <section className="item-details-area pt-5">
            <div className='container'>
                {
                    (auction_arts.length > 1) ? 
                    <div className="row justify-content-end">
                        <div className="col-md-4 align-self-end ">
                            <input type="text" id="search_form" className="form-control mb-2 mr-sm-2" onChange={e => searchArts(e)} placeholder="Search Art Code"></input>
                        </div>
                    </div> : ""
                }
                

                { 
                    searchKey != '' ?
                        <div class="col-12 col-md-12 p-2" style={soldOut}>
                            <p className='text-dark'>Result found for <b>"{searchKey}"</b> key. <a style={{cursor:'pointer'}} className='text-danger' onClick={() => clearSearch()}>Clear Search</a></p>
                        </div>
                    : ''
                }
                {   
                    isLoaded ? (
                        
                        (auction_arts.length > 0) ? (
                            auction_arts.map((art, i) =>
                                <div >
                                    <AuctionItem key={i} 
                                        auction_id = {art.auction_id}
                                        auction_art_id = {art.id}
                                        art_id = {art.art_id}
                                        label = {art.label}
                                        merch_image = {art.merch_image}
                                        merch_title = {art.merch_title}
                                        total_offers = {art.total_offers}
                                        status = {art.status}
                                        auctionstatus = {props.auction_status}
                                        auctionallowed = {props.auction_allowed}
                                        art_code = {art.art_code}
                                        art_details={art.art_details}
                                        artist={art.artist}
                                        highest_offer={parseInt(art.highest_offer)}
                                        getAuctionArts={getAuctionArts}
                                        base_price={parseInt(art.bid_start)}
                                        base_price_eth={parseInt(art.base_price_eth)}
                                        product_details={props.product_details}
                                        shipping_details={props.shipping_details}
                                        auction_code={props.auction_code}
                                        free={props.free}
                                        placeBids={() => placeBids(art)}
                                        viewMerch={() => viewMerch(art)}
                                    />
                                </div>
                            ) 
                        ) : <Alert show={true} variant="success" className="d-flex justify-content-between">
                                <p className="mb-0">Sorry, No Auction Arts Available</p> 
                            </Alert>
                        
                    ) : (<AuctionItemSkeleton arts={3}/>)
                }
            </div>

            {
                auction_art_id && auction_id && modalType ? (
                    <ModalAuctions isShow={isShow}
                        closeModal={closeModal}
                        modalType={modalType} 
                        auction_art_id={auction_art_id} 
                        auction_id = {auction_id}
                        setModalType={setModalType}
                        getAuctionArts={getAuctionArts}
                        base_price={parseInt(artBasePrice)}
                        base_price_eth={parseInt(props.base_price_eth)}
                        auction_code={props.auction_code}
                        auctionstatus = {props.auction_status}
                    />
                ) : null
            }
        
        <Modal size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered show={showMerch}>
            <Modal.Header className="pb-0">
                <p className="font-weight-bold pt-3">View Merch</p> <button type="button" className="btn btn-dark btn-sm rounded-0 p-2" onClick={closeModal}>x</button> 
            </Modal.Header>
            <Modal.Body className="pt-0">
                <img alt="img" className='img-fluid' src={merchImage}></img>
            </Modal.Body>
        </Modal>
        {/* <Modal id={'auctionMerchImg'} title="View Merch"  size="lg" product={props.product_details} shipping={props.shipping_details}/> */}

        </section>
    )

}

export default Auction;